import { View, Text } from "dripsy";
import useScale from "../../hooks/useScale";

export const ContentContainer = ({ title, showTitle = true, children }) => {
    const scale = useScale();
    return (
      <View sx={{ pt: "$3", pb: "$2" }}>
        {showTitle && (
          <View
            sx={{ borderBottomWidth: 0.8, borderBottomColor: "grey", pb: "$2" }}
          >
            <Text sx={{ fontWeight: 500, fontSize: scale(14) }}>{title}</Text>
          </View>
        )}
  
        {children}
      </View>
    );
};