import Footer from "@components/Footer";
import Header from "@components/Header";
import Nav from "@components/Nav";
import { View } from "dripsy";
import { useWindowDimensions } from "react-native";

export default function DefaultLayout({ children }) {
  const { height } = useWindowDimensions();
  return (
    <View sx={{ flex: 1, backgroundColor: "$background" }}>   
      {/* <Nav /> */}
      <Header />
      <View sx={{ minHeight: height }}>{children}</View>
      <Footer />
    </View>
  );
}
