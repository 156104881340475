import { Text } from "dripsy";
import useScale from "../../hooks/useScale";

export const EmptyData = () => {
    const scale = useScale();
    return (
      <Text
        sx={{
          fontSize: [scale(14), scale(12)],
          fontStyle: "italic",
          textAlign: "center",
          pt: "$1",
          pb: "$2",
        }}
      >
        Chưa có thông tin
      </Text>
    );
  };