import { View, Text } from "dripsy";
import { ContentContainer } from  "./ContentContainer";
import isEmpty from "lodash/isEmpty";

export const GplxInfo = ({ data }) => {
  const hasNoData = isEmpty(data);
  return (
    !hasNoData && (
      <ContentContainer title={"Giấy phép lái xe"}>
        <View sx={{ py: "$2" }}>
          {data?.map((item, index) => {
            const { loaiGPLX, ngayCap, ngayHetHan, noiCap } = item;
            return (
              <Text sx={{ mt: index > 0 ? "$2" : null }} key={index}>
                {`Giấy phép ${loaiGPLX}, nơi cấp ${noiCap}, ngày cấp ${ngayCap}, ngày hết hạn ${ngayHetHan}`}
              </Text>
            );
          })}
        </View>
      </ContentContainer>
    )
  );
};