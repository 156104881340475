import { View, Text } from "dripsy";
import useScale from "../../hooks/useScale";
import isEmpty from "lodash/isEmpty";
import { ContentContainer } from  "./ContentContainer";
import { InfoItem } from  "./InfoItem";

export const HealthInfo = ({ data, showContent = false }) => {
    const scale = useScale();
    console.log("HealthInfo.data", data);
    if (showContent) {
      return (
        <ContentContainer title={"Sức khoẻ"}>
          {data &&
            <View sx={{ py: "$2" }}>
              {Array.isArray(data) ? (
                <>
                  {data?.map(({ ngayKham, chieuCao, canNang, nhomMau }, index) => {
                    return (
                      <Text
                        sx={{ mt: index > 0 ? "$2" : null }}
                        key={index}
                      >{`- Ngày cập nhật: ${ngayKham}, chiều cao ${chieuCao}(cm), cân nặng ${canNang}(kg), nhóm máu: ${nhomMau} `}</Text>
                    );
                  })}
                </>
              ) : (
                <Text>{`Chiều cao ${data?.chieuCao}, cân nặng ${data?.canNang}, nhóm máu: ${data?.nhomMau} `}</Text>
              )}
            </View>
          }
          {!data &&
            <View sx={{ marginTop: 10 }}>
              <Text>Không có dữ liệu</Text>
            </View>
          }
        </ContentContainer>
      );
    }
  
    return (
      <View sx={{ mt: "$2" }}>
        <Text
          sx={{
            py: "$2",
            px: "$2",
            mb: "$2",
            textAlign: "center",
            bg: "#367BDD",
            color: "$white",
            fontSize: [scale(14), scale(12)],
          }}
        >
          {"Sức khoẻ"}
        </Text>
        {isEmpty(data) ? (
          <EmptyData />
        ) : (
          <>
            {Array.isArray(data) ? (
              <>
                {data?.map((item, index) => {
                  return (
                    <>
                      <InfoItem label={"Chiều cao"} value={item.chieuCao} />
                      <InfoItem label={"Cân nặng"} value={item.canNang} />
                      <InfoItem label={"Nhóm máu"} value={item.nhomMau} />
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <InfoItem label={"Chiều cao"} value={data?.chieuCao} />
                <InfoItem label={"Cân nặng"} value={data?.canNang} />
                <InfoItem label={"Nhóm máu"} value={data?.nhomMau} />
              </>
            )}
          </>
        )}
      </View>
    );
  };