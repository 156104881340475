import { View, Text } from "dripsy";
import useScale from "../../hooks/useScale";
import { ContentContainer } from  "./ContentContainer";
import { InfoItem } from  "./InfoItem";
import AspectRatioImage from "@components/AspectRatioImage";
export const GeneralInfo = ({
    data: {
      node: {
        hoTen,
        gioiTinh,
        ngaySinh,
        noiSinh,
        queQuan,
        danToc,
        quocTich,
        cccd,
        hocVan = "",
        quanHeChuHo,
        ngayChuyenDen,
        noiTruocChuyenDen,
        image,
      },
    },
    showContent = false,
    showTitle = false,
  }) => {
    const scale = useScale();
  
    if (showContent) {
      return (
        <ContentContainer showTitle={showTitle}>
          <View sx={{ pt: "$2", pb: "$3" }}>
            <Text sx={{ mt: "$2", fontSize: scale(12) }}>
              {`Họ và Tên: `} <Text sx={{ fontWeight: 500 }}>{hoTen}</Text>
            </Text>
            <Text sx={{ mt: "$2", fontSize: scale(12) }}>
              {`Ngày sinh: `} <Text sx={{ fontWeight: 500 }}>{ngaySinh}</Text>
            </Text>
            <Text sx={{ mt: "$2", fontSize: scale(12) }}>
              {`Nơi sinh: `} <Text sx={{ fontWeight: 500 }}>{noiSinh}</Text>
            </Text>
            <Text sx={{ mt: "$2", fontSize: scale(12) }}>
              {`Quốc tịch: `} <Text sx={{ fontWeight: 500 }}>{quocTich}</Text>
            </Text>
            <Text sx={{ mt: "$2", fontSize: scale(12) }}>
              {`Trình độ học vấn: `} <Text sx={{ fontWeight: 500 }}>{hocVan}</Text>
            </Text>
          </View>
        </ContentContainer>
      );
    }
    return (
      <View>
        <AspectRatioImage
          uri={image}
          aspectWidth={200}
          style={{ alignSelf: "center", marginVertical: 20 }}
        />
        <Text
          sx={{
            fontSize: [scale(16), scale(14)],
            textAlign: "center",
            py: "$2",
            fontWeight: 400,
            bg: "#367BDD",
            color: "$white",
          }}
        >
          {hoTen}
        </Text>
        <InfoItem label={"Giới tính"} value={gioiTinh} />
        <InfoItem label={"Ngày sinh"} value={ngaySinh} />
        <InfoItem label={"Nơi sinh"} value={noiSinh} />
        <InfoItem label={"Quê quán"} value={queQuan} />
        <InfoItem label={"Dân tộc"} value={danToc} />
        <InfoItem label={"Quốc tịch"} value={quocTich} />
        <InfoItem label={"CCCD"} value={cccd} />
        <InfoItem label={"Trình độ học vấn"} value={hocVan} />
        <InfoItem label={"Quan hệ chủ hộ"} value={quanHeChuHo} />
        <InfoItem label={"Ngày chuyển đến"} value={ngayChuyenDen} />
        <InfoItem label={"Nơi trước chuyển đến"} value={noiTruocChuyenDen} />
      </View>
    );
  };