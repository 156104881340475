import { View, Text, Pressable } from "dripsy";
import useScale from "../../hooks/useScale";
import { InfoItem } from  "./InfoItem";

export const FamilyInfo = ({ data, onLinkPress }) => {
  const scale = useScale();
  return (
    <View sx={{ mt: "$2" }}>
      <Text
        sx={{
          py: "$2",
          px: "$2",
          mb: "$2",
          textAlign: "center",
          bg: "#367BDD",
          color: "$white",
          fontSize: [scale(14), scale(12)],
        }}
      >
        Gia đình
      </Text>
      {(data?.length || 0) === 0 ? (
        <EmptyData />
      ) : (
        data?.map((item, index) => {
          const { type, hoTen, cccd } = item || {};
          return (
            <Pressable
              key={index}
              sx={{
                ...(index > 0 && {
                  borderTopWidth: 0.8,
                  borderTopColor: "grey",
                }),
              }}
              onPress={() => onLinkPress && onLinkPress(cccd)}
            >
              <InfoItem label={"Quan hệ"} value={type} />
              <InfoItem label={"Họ Tên"} value={hoTen} isLink={true} />
              <InfoItem label={"CCCD"} value={cccd} />
            </Pressable>
          );
        })
      )}
    </View>
  );
};