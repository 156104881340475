import { View, Text } from "dripsy";
import useScale from "../../hooks/useScale";
import { ContentContainer } from  "./ContentContainer";
import { InfoItem } from  "./InfoItem";

export const EducationInfo = ({ data, showContent = false }) => {
    const scale = useScale();
    const hasNoData = (data?.length || 0) === 0;
    if (showContent) {
      return (
        !hasNoData && (
          <ContentContainer title={"Giáo dục"}>
            <View sx={{ py: "$2" }}>
              {data?.map((item, index) => {
                const {
                  capHoc,
                  tenTruong,
                  namHocBatDau,
                  namHocKetThuc,
                  bangCap,
                  loaiTotNghiep,
                  diaDiem,
                } = item;
                return (
                  <Text
                    key={`${index}`}
                    sx={{ mt: "$2" }}
                  >{`Học ${capHoc} trường ${tenTruong} tại ${diaDiem} từ ${namHocBatDau}, tốt nghiệp loại ${loaiTotNghiep} vào năm ${namHocKetThuc}`}</Text>
                );
              })}
            </View>
          </ContentContainer>
        )
      );
    }
    return (
      <View sx={{ mt: "$2" }}>
        <Text
          sx={{
            py: "$2",
            px: "$2",
            mb: "$2",
            textAlign: "center",
            bg: "#367BDD",
            color: "$white",
            fontSize: [scale(14), scale(12)],
          }}
        >
          {"Học vấn"}
        </Text>
  
        {hasNoData ? (
          <EmptyData />
        ) : (
          data?.map((item, index) => {
            const {
              capHoc,
              tenTruong,
              namHocBatDau,
              namHocKetThuc,
              bangCap,
              loaiTotNghiep,
              diaDiem,
            } = item;
            return (
              <View
                key={index}
                sx={{ borderBottomWidth: 0.8, borderBottomColor: "grey" }}
              >
                <InfoItem label={"Cấp học"} value={capHoc} />
                <InfoItem label={"Tên trường"} value={tenTruong} />
                <InfoItem
                  label={"Thời gian"}
                  value={`${namHocBatDau} - ${namHocKetThuc}`}
                />
                <InfoItem label={"Bằng cấp"} value={bangCap} />
                <InfoItem label={"Tốt nghiệp loại"} value={loaiTotNghiep} />
                <InfoItem label={"Địa điểm"} value={diaDiem} />
              </View>
            );
          })
        )}
      </View>
    );
  }; 