import { ActivityIndicator, View } from "dripsy";

export default function Loading() {
  return (
    <View
      sx={{
        flexGrow: 1,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ActivityIndicator size={"large"} sx={{ my: "$3" }} color={"#0072B1"} />
    </View>
  );
}
