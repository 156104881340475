import { makeTheme } from "dripsy";

const fontName = "Inter_400Regular";

export const theme = makeTheme({
  customFonts: {
    [fontName]: {
      default: fontName,
      normal: fontName,
      bold: "Inter_700Bold",
      extraBold: "Inter_800ExtraBold",
      black: "Inter_900Black",
      100: "Inter_100Thin",
      200: "Inter_200ExtraLight",
      300: "Inter_300Light",
      400: fontName,
      500: "Inter_500Medium",
      600: "Inter_600SemiBold",
      700: "Inter_700Bold",
      800: "Inter_800ExtraBold",
      900: "Inter_900Black",
    },
  },
  fonts: {
    root: fontName,
  },
  colors: {
    $primary: "#0072B1",
    $secondary: "#1257BE",
    $white: "#FFFFFF",
    $black: "#000000",
    $background: "#F5F5F5",
    $text: "#0F2030",
  },
  space: {
    $none: 0,
    $0: 0,
    $1: 4,
    $2: 8,
    $3: 16,
    $4: 32,
    $5: 64,
    $6: 128,
    $7: 256,
    $8: 512,
  },
  types: {
    onlyAllowThemeValues: {
      space: "always",
    },
  },
});
