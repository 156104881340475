import { useState, useEffect, useCallback } from "react";
import { View, Text, Pressable } from "dripsy";
import Container from "../components/Container";
import useScale from "../hooks/useScale";
import HttpClient from "../httpClient";
import DefaultLayout from "@layout/default";
import { MaterialIcons } from "@expo/vector-icons";
import { useLinkTo } from "@react-navigation/native";
import Loading from "@components/Loading";
import EmptyData from "@components/EmptyData";

const ItemDetail = ({ icon, label, value, renderValueComponent }) => {
  const scale = useScale();
  return (
    <View
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderTopWidth: 1,
        borderTopColor: "#eceeef",
        py: "$3",
      }}
    >
      <View sx={{ flexDirection: "row", alignItems: "center" }}>
        <MaterialIcons name={icon} size={28} />
        <Text sx={{ pl: "$3", fontSize: [scale(14), scale(12)] }}>{label}</Text>
      </View>
      {renderValueComponent ? (
        renderValueComponent(value)
      ) : (
        <Text sx={{ fontSize: [scale(14), scale(12)] }}>{value}</Text>
      )}
    </View>
  );
};

export default function BusinessDetails({
  route: { params: { id = "" } = {} },
}) {
  const scale = useScale();
  const [data, setData] = useState(null);
  const linkTo = useLinkTo();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await HttpClient.get({
          method: `/doanhnghiep/${id}`,
        });
        console.log("fetchData.response", response);
        setData(response);
      } catch (error) {
        console.log("fetchData.error", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const { node: business, relationships } = data || {};

  console.log("BusinessDetails.data", business);
  console.log("BusinessDetails.relationships", relationships);

  const onPersonLinkPress = useCallback(() => {
    if (business?.cccdNguoiDaiDien) {
      linkTo(`/details/${business.cccdNguoiDaiDien}`);
    }
  }, [business]);

  return (
    <DefaultLayout>
      <Container flexGrow={1} bg={"$background"}>
        {isLoading ? (
          <Loading />
        ) : business ? (
          <View
            sx={{
              py: "$3",
              bg: "$white",
              px: "$3",
              mt: "$3",
            }}
          >
            <Text sx={{ fontSize: scale(14), fontWeight: "bold" }}>
              {business.tenDoanhNghiep}
            </Text>
            <View sx={{ px: "$3", mt: "$3" }}>
              <ItemDetail
                icon={"business"}
                label={"Mã số thuế"}
                value={business.maSoThue}
              />
              <ItemDetail
                icon={"location-on"}
                label={"Địa chỉ"}
                value={business.diaChi}
              />
              <ItemDetail
                icon={"person"}
                label={"Người đại diện"}
                value={business.hoTenNguoiDaiDien}
                renderValueComponent={(value) => {
                  return (
                    <Pressable onPress={onPersonLinkPress}>
                      <Text
                        sx={{
                          textDecorationLine: "underline",
                          color: "rgb(54, 123, 221)",
                          fontSize: [scale(14), scale(12)],
                        }}
                      >
                        {value}
                      </Text>
                    </Pressable>
                  );
                }}
              />
              <ItemDetail
                icon={"phone"}
                label={"Số điện thoại"}
                value={business.dienThoai}
              />
              <ItemDetail
                icon={"calendar-today"}
                label={"Ngày hoạt động"}
                value={business.ngayHoatDong}
              />
              <ItemDetail
                icon={"groups"}
                label={"Quản lý bởi"}
                value={business.quanLyBoi}
              />
              <ItemDetail
                icon={"corporate-fare"}
                label={"Loại hình doanh nghiệp"}
                value={business.loaiHinhDn || business.loaiHinhDN}
              />
              <ItemDetail
                icon={"info"}
                label={"Tình trạng"}
                value={business.tinhTrang}
              />
            </View>
          </View>
        ) : (
          <EmptyData />
        )}
      </Container>
    </DefaultLayout>
  );
}
