import { useState, useCallback, useEffect } from "react";
import { useWindowDimensions } from "react-native";
import { View, Text, TextInput, Image, Pressable } from "dripsy";
import { Feather } from "@expo/vector-icons";
import useScale from "../hooks/useScale";
import { useLinkTo } from "@react-navigation/native";

const banner = require("../../assets/header-home.jpeg");

export default function Header() {
  const linkTo = useLinkTo();
  const { width } = useWindowDimensions();
  const scale = useScale();

  const [keyword, setKeyword] = useState("");

  const onSubmit = useCallback(({ nativeEvent: { text } }) => {
    linkTo(`/search/${text}`);
    setKeyword("");
  }, []);

  const onSearchPress = useCallback(() => {
    if (keyword) {
      linkTo(`/search/${keyword}`);
      setKeyword("");
    }
  }, [keyword]);

  const onGoHomePress = () => {    
    linkTo(`/home`);
  };

  return (
    <View sx={{ overflowX: "hidden" }}>
      <Image
        source={banner}
        style={{ width, height: width / 5 }}
      />
      <View
        sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
        }}>
        <Text
          sx={{
                color: "$white",
                fontSize: [scale(22), scale(24)],
                fontWeight: 700,
                marginBottom: 15,
          }}>
          Cổng Tra Cứu Thông Tin Tỉnh
        </Text>
        <View
          sx={{
                flexDirection: "row",
                borderRadius: 4,
                width: ["10%", "90%"],
                alignItems: "center",
                justifyContent: "center",
          }}>
          <Pressable
            style={{
                background: "rgba(0,0,0,0.7)",
                color: "$white",
                justifyContent: "center",
                paddingHorizontal: 16,
                borderRadius: 4,
                marginTop: 15,
                marginRight: 15,
                paddingTop: 14,
                paddingBottom: 14,
            }}
            onPress={onGoHomePress}>
            <Feather
                name="home"
                size={24}
                color="white"
            />
          </Pressable>
          <View
            sx={{
                flexDirection: "row",
                bg: "$white",
                borderRadius: 4,
                width: ["80%", "60%", "50%", "30%"],
                alignItems: "center",
                mt: ["$2", "$3"],
            }}>
            <TextInput
                sx={{
                        py: ["$2", "$3"],
                        fontSize: 18,
                        flexGrow: 1,
                        pl: "$3",
                        pr: 40,
                }}
                value={keyword}
                placeholder={"Tìm kiếm"}
                placeholderTextColor={"grey"}
                numberOfLines={1}
                onChangeText={(text) => setKeyword(text)}
                onSubmitEditing={onSubmit}
            />
            <Pressable
                style={{
                    position: "absolute",
                    right: 0,
                    height: "100%",
                    justifyContent: "center",
                    paddingHorizontal: 16,
                }}
                onPress={onSearchPress}>
              <Feather
                name="search"
                size={24}
                color="black"
              />
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
}
