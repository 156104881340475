import { View, Text } from "dripsy";
import Container from "./Container";
import useScale from "@hooks/useScale";

export default function Footer(props) {
  const scale = useScale();
  const normalTextSx = {
    color: "$white",
    fontWeight: 400,
    mt: "$3",
  };

  return (
    <View sx={{ bg: "#113354", mt: "$3" }}>
      <Container>
        <View
          sx={{ flexDirection: ["column", "row"], py: "$4", px: ["$3", "$0"] }}
        >
          <View sx={{ flex: [null, 2] }}>
            <Text
              sx={{
                color: "$white",
                fontSize: [scale(20), scale(18)],
                fontWeight: 300,
              }}
            >
              Được xây dựng bởi
            </Text>
            <Text sx={{ color: "$white", fontWeight: 400, mt: ["$3", "$4"] }}>
              DTC Solution
            </Text>
            <Text sx={{ color: "$white", fontWeight: 300, mt: "$3" }}>
              Cập nhật vào 5/12/2022
            </Text>
            <Text sx={{ color: "$white", fontWeight: 300, mt: "$2" }}>
              © 2022 DTC Solution
            </Text>
          </View>
          <View sx={{ flex: [null, 1], mt: ["$4", "$0"] }}>
            <Text
              sx={{
                color: "$white",
                fontSize: [scale(20), scale(18)],
                fontWeight: 300,
              }}
            >
              Liên kết
            </Text>
            <Text sx={normalTextSx}>Về chúng tôi</Text>
            <Text sx={normalTextSx}>Điều khoản</Text>
            <Text sx={normalTextSx}>Giấy phép dữ liệu mở</Text>
            <Text sx={normalTextSx}>Nhà phát triển</Text>
          </View>
          {/* <View sx={{ flex: [null, 1], mt: ["$4", "$0"] }}>
            <Text
              sx={{
                color: "$white",
                fontSize: [scale(20), scale(18)],
                fontWeight: 300,
              }}
            >
              Liên hệ
            </Text>
            <Text sx={normalTextSx}>Email</Text>
            <Text sx={normalTextSx}>Facebook</Text>
            <Text sx={normalTextSx}>Twitter</Text>
            <Text sx={normalTextSx}>Medium</Text>
            <Text sx={normalTextSx}>Github</Text>
          </View> */}
        </View>
      </Container>
    </View>
  );
}
