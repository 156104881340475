import { View, Text, useSx, Pressable } from "dripsy";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import useScale from "../hooks/useScale";
import Container from "./Container";
import { useLayout } from "../hooks/useLayout";
import { useLinkTo, useLinkBuilder, useRoute } from "@react-navigation/native";

export const TOPICS = [
  {
    icon: "cast-education",
    title: "Giáo dục",
    path: "/topics/giao-duc",
  },
  {
    icon: "cash-fast",
    title: "Tài chính",
    path: "/topics/tai-chinh",
  },
  {
    icon: "hand-heart",
    title: "Y Tế",
    path: "/topics/y-te",
  },
  {
    icon: "greenhouse",
    title: "Xây Dựng",
    path: "/topics/xay-dung",
  },
  {
    icon: "monitor-cellphone",
    title: "Công nghệ",
    path: "/topics/cong-nghe",
  },
  {
    icon: "car-electric",
    title: "Giao thông",
    path: "/topics/giao-thong",
  },
];

export const Topic = ({ icon, title, width, path, isActive }) => {
  const scale = useScale();
  const linkTo = useLinkTo();
  return (
    <Pressable
      sx={{ width, justifyContent: "center", alignItems: "center", py: "$3" }}
      onPress={() => linkTo(path)}
    >
      {({ hovered }) => (
        <>
          <MaterialCommunityIcons
            name={icon}
            size={28}
            color={hovered || isActive ? "#FF7733" : "black"}
          />
          <Text
            sx={{
              fontSize: [scale(10), scale(12)],
              mt: "$3",
              color: hovered || isActive ? "#FF7733" : "black",
            }}
          >
            {title}
          </Text>
        </>
      )}
    </Pressable>
  );
};

export default function Topics(props) {
  const { onLayout, width } = useLayout();
  const sx = useSx();

  const route = useRoute();
  const buildLink = useLinkBuilder();
  const linking = buildLink(route.name, route.params);

  const { columnCount } = sx({
    columnCount: [3, 5, 8, 10],
  });

  const itemWidth = width / columnCount;

  return (
    <Container bg={"$white"} onLayout={onLayout}>
      {width !== 0 && (
        <View
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {TOPICS.map((topic, index) => {
            const isActive = linking.startsWith(topic.path);
            return (
              <Topic
                isActive={isActive}
                key={index}
                icon={topic.icon}
                title={topic.title}
                path={topic.path}
                width={itemWidth}
              />
            );
          })}
        </View>
      )}
    </Container>
  );
}
