import { DripsyProvider } from "dripsy";
import { useEffect } from "react";
import { theme } from "./src/theme";
import Home from "./src/pages/home";
import Details from "./src/pages/details";
import SearchResult from "@pages/search-result";
import TopicDetails from "@pages/topic-details";
import BusinessDetails from "@pages/business-details";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import mgr from "./src/config/oidcConfig";
import {
  useFonts,
  Inter_100Thin,
  Inter_200ExtraLight,
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from "@expo-google-fonts/inter";

import { Platform } from 'react-native';
const title = (text) => Platform.select({ web: `Cổng Tra Cứu Thông Tin Tỉnh | ${text}`, default: text });

const Stack = createStackNavigator();

const linking = {
  config: {
    screens: {
      Home: {
        path: "/home",
      },
      SearchResult: {
        path: "/search/:keyword",
      },
      Details: {
        path: "/details/:id",
      },
      TopicDetails: {
        path: "/topics/:id",
      },
      BusinessDetails: {
        path: "/business-details/:id",
      },
    },
  },
};

export default function App() {
    useEffect(async () => {
      if (window.location.href.indexOf('code=') > -1) {
        const user = await mgr.signinRedirectCallback();
        if (user) {
            window.location.href = '/';
        }
    } else {
        const user = await mgr.getUser();
        if (!user) {
            mgr.signinRedirect();
        }
    }
    }, [])
  let [fontsLoaded] = useFonts({
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });

  if (!fontsLoaded) {
    return null;
  } else {
    return (
      <DripsyProvider theme={theme}>
        <NavigationContainer linking={linking}>
          <Stack.Navigator
            initialRouteName="Home"
            screenOptions={{ headerShown: false }}
          >
            <Stack.Screen name="Home" component={Home} options={{ title: title('Trang chủ') }}/>
            <Stack.Screen name="SearchResult" component={SearchResult}  options={{ title: title('Kết quả tìm kiếm') }}/>
            <Stack.Screen name="Details" component={Details}  options={{ title: title('Chi tiết') }}/>
            <Stack.Screen name="TopicDetails" component={TopicDetails} options={{ title: title('Chi tiết lĩnh vực') }}/>
            <Stack.Screen name="BusinessDetails" component={BusinessDetails} options={{ title: title('Chi tiết doanh nghiệp') }}/>
          </Stack.Navigator>
        </NavigationContainer>
      </DripsyProvider>
    );
  }
}
