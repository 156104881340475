import { useWindowDimensions } from "react-native";
import { useState } from "react";
import { View, useSx, Image, Text } from "dripsy";
import Container from "./Container";
import { useLayout } from "../hooks/useLayout";

const getPathname = window.location.pathname;

console.log("getPathname", getPathname);

export default function Sections({ id }) {
  console.log("Sections.id", id);
  const sx = useSx();
  const { columnCount, columnGap } = sx({
    columnCount: [1, 2],
    columnGap: [0, 12],
  });
  const { onLayout } = useLayout();
  const itemWidth = (width - columnCount * columnGap) / columnCount;
  const { width } = useWindowDimensions();

  const styles = {
    title: {
      fontFamily: "Inter_700Bold",
      color: "rgba(74,144,226,1)",
      fontSize: 30,
      marginTop: 20,
    },
    subTitle: {
      fontFamily: "Inter_700Bold",
      color: "#121212",
      fontSize: 20,
      marginTop: 15,
    },
    content: {
      fontFamily: "Tahoma",
      color: "#555",
      lineHeight: 22,
      fontSize: 16,
      textAlign: "justify",
      marginTop: 10,
    },
  };

  return (
    <Container onLayout={onLayout}>
      {width !== 0 && (
        <View
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <View
            sx={{
              flexDirection: "column",
              flexWrap: "wrap",
              width: ["100%", "100%"],
              backgroundColor: "#fff",
              marginTop: 15,
              marginBottom: 20,
              justifyContent: "space-between",
            }}
          >
            <View
              sx={{
                flexDirection: "row",
                width: ["100%"],
                justifyContent: "space-between",
              }}
            >
              <View
                sx={{
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: ["100%", "30%"],
                }}
              >
                <Image
                  source={require("../../assets/image2.gif")}
                  style={{
                    width: "100%",
                    height: (14.5 * width) / 100,
                    marginTop: 15,
                  }}
                />
              </View>
              <View
                sx={{
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: ["100%", "70%"],
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <Text sx={styles.title}>
                  Tra cứu thông tin Công dân và Doanh nghiệp
                </Text>
                <Text sx={styles.content}>
                  ▶️ Kho dữ liệu công dân, doanh nghiệp đã được làm giàu thêm dữ
                  liệu y tế, giáo dục, quốc phòng, thông tin nộp thuế…
                </Text>
                <Text sx={styles.content}>
                  ▶️ Giúp tổ chức, cá nhân, doanh nghiệp muốn tìm hiểu con
                  người, lực lượng lao động, doanh nghiệp của địa phương để kinh
                  doanh, hợp tác xây dựng doanh nghiệp trên địa bàn tỉnh.
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 15,
                borderBottomColor: "#e0e0e0",
                borderBottomWidth: 1,
                backgroundColor: "#f5f5f5",
              }}
            />

            <View
              sx={{
                flexDirection: "column",
                width: ["100%"],
                justifyContent: "end",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <Text sx={styles.title}>Tra cứu bằng công nghệ eKYC</Text>
              <View
                sx={{
                  flexDirection: "row",
                  width: ["100%"],
                  marginTop: 15,
                  justifyContent: "space-between",
                }}
              >
                <View
                    sx={{
                        flexDirection: "column",
                        flexWrap: "wrap",
                        width: ["100%", "50%"],
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                  <Image
                    source={require("../../assets/info-1.jpg")}
                    style={{
                      width: "100%",
                      height: (23.5 * width) / 100,
                      marginTop: 15,
                    }}
                  />
                </View>
                <View
                    sx={{
                        flexDirection: "column",
                        flexWrap: "wrap",
                        justifyContent: "end",
                        alignItems: "center",
                        width: ["100%", "50%"],
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                  <Image
                    source={require("../../assets/image6.jpeg")}
                    style={{
                      width: "50%",
                      height: (10.5 * width) / 100,
                      marginTop: 15,
                    }}
                  />
                  <Text sx={styles.subTitle}>CSDL Người dân</Text>
                  
                  <Image
                    source={require("../../assets/image7.png")}
                    style={{
                      width: "50%",
                      height: (10.5 * width) / 100,
                      marginTop: 15,
                    }}
                  />
                  <Text sx={styles.subTitle}>CSDL Doanh nghiệp</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: 30,
                borderBottomColor: "#e0e0e0",
                borderBottomWidth: 1,
              }}
            />
            <View
                sx={{
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "end",
                    alignItems: "center",
                    width: ["100%", "100%"],
                    paddingLeft: 20,
                    paddingRight: 20,
                }}
            >
                <Text sx={styles.title}>
                    Tra cứu thông qua nhận dạng hình ảnh
                </Text>
                
                <Image
                    source={require("../../assets/info-2.jpg")}
                    style={{
                      width: "100%",
                      height: (28.5 * width) / 100,
                      marginTop: 15,
                    }}
                  />
            </View>
          </View>
        </View>
      )}
    </Container>
  );
}
