import React from "react";
import Topics from "../components/Topics";
import Sections from "../components/Sections";
import DefaultLayout from "@layout/default";

export default function TopicDetails({ route: { params: { id = "" } = {} } }) {
  console.log("TopicDetails.id", id);
  return (
    <DefaultLayout>
      <>
        <Topics />
        <Sections id={id} />
      </>
    </DefaultLayout>
  );
}
