import { useEffect, useCallback, useState } from "react";
import { View, Text, Pressable } from "dripsy";
import Container from "@components/Container";
import useScale from "@hooks/useScale";
import HttpClient from "../httpClient";
import { useLinkTo } from "@react-navigation/native";
import { formatDate } from "../datetime";
import DefaultLayout from "@layout/default";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { TOPICS } from "@components/Topics";
import Loading from "@components/Loading";

const INDEX_TYPE = {
  CONDAN: "congdan",
  DOANHNGHIEP: "doanhnghiep",
};

const Item = ({ title, value, titleStyle }) => {
  const scale = useScale();

  const textStyle = {
    fontSize: [scale(14), scale(12)],
    fontWeight: 300,
    mt: "$2",
    ...titleStyle,
  };

  const data = Array.isArray(value) ? value[0] : value;

  return (
    <View sx={{ flexDirection: "row", alignItems: "center" }}>
      <Text sx={textStyle}>{title}</Text>
      {data?.split(/(<em>.*?<\/em>)/).map((x, index) => {
        return x.includes("<em>") ? (
          <Text key={index} sx={{ ...textStyle, color: "#367BDD" }}>
            {x.replace("<em>", "").replace("</em>", "")}
          </Text>
        ) : (
          <Text key={index} sx={{ ...textStyle }}>
            {x}
          </Text>
        );
      })}
    </View>
  );
};

const SearchResultItem = ({ data, index }) => {
  const linkTo = useLinkTo();
  const scale = useScale();

  const id = data["_id"];
  const { hoTen, cccd, gioiTinh, ngaySinh, queQuan, danToc, quocTich } =
    data["_source"];

  const onPress = useCallback(() => {
    linkTo(`/details/${id}`);
  }, [id]);

  return (
    <View>
      <Pressable
        sx={{
          py: "$2",
          px: "$3",
          ...(index > 0 && {
            borderTopWidth: 1,
            borderTopColor: "$background",
          }),
        }}
        onPress={onPress}
      >
        <Item
          title={""}
          value={hoTen}
          titleStyle={{ fontSize: [scale(16), scale(14)], fontWeight: 400 }}
        />
        <Item title={"CCCD: "} value={cccd} />
        <Item title={"Giới tính: "} value={gioiTinh} />
        <Item
          title={"Ngày sinh: "}
          value={formatDate(ngaySinh, "DD/MM/YYYY")}
        />
        <Item title={"Quê quán: "} value={queQuan} />
        <Item title={"Dân tộc: "} value={danToc} />
        <Item title={"Quốc tịch: "} value={quocTich} />
      </Pressable>
    </View>
  );
};

const BusinessResultItem = ({ data, index }) => {
  const {
    _source: { tenDoanhNghiep, maSoThue, hoTenNguoiDaiDien, diaChi },
  } = data;
  const scale = useScale();
  const linkTo = useLinkTo();

  const onPress = useCallback(() => {
    linkTo(`/business-details/${maSoThue}`);
  }, [maSoThue]);

  return (
    <Pressable
      sx={{
        py: "$2",
        px: "$3",
        ...(index > 0 && {
          borderTopWidth: 1,
          borderTopColor: "$background",
        }),
      }}
      onPress={onPress}
    >
      <Item
        title={""}
        value={tenDoanhNghiep}
        titleStyle={{ fontSize: [scale(16), scale(14)], fontWeight: 400 }}
      />

      <Item title={"Mã số thuế: "} value={maSoThue} />
      <Item title={"Người đại diện: "} value={hoTenNguoiDaiDien} />
      <Item title={"Địa chỉ: "} value={diaChi} />
    </Pressable>
  );
};

export default function SearchResult({
  route: { params: { keyword = "" } = {} },
}) {
  const [data, setData] = useState([]);
  const scale = useScale();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const doSearch = async () => {
      try {
        setIsLoading(true);
        const response = await HttpClient.post({
          method: "/search",
          body: {
            query: keyword,
          },
        });
        console.log("SearchResult", response);
        setData(response);
      } catch (error) {
        console.log("SearchResult.error", error);
      } finally {
        setIsLoading(false);
      }
    };
    doSearch();
  }, [keyword]);

  return (
    <DefaultLayout>
      <Container flexGrow={1}>
        {isLoading ? (
          <Loading />
        ) : (
          <View sx={{ px: "$3", flexDirection: ["column", "row"], py: "$3" }}>
            {/* <View sx={{ flex: [null, 1] }}>
              <Text sx={{ fontSize: scale(20), pb: "$3" }}>Topics</Text>
              {TOPICS.map((topic, index) => {
                const { icon, title } = topic;
                return (
                  <Pressable
                    key={index}
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      py: "$3",
                      bg: "$white",
                      pl: "$3",
                    }}
                  >
                    {({ hovered }) => (
                      <>
                        <MaterialCommunityIcons
                          name={icon}
                          size={32}
                          color={hovered ? "#FF7733" : "black"}
                        />
                        <Text
                          sx={{
                            fontSize: [scale(10), scale(12)],
                            pl: "$3",
                            color: hovered ? "#FF7733" : "black",
                          }}
                        >
                          {title}
                        </Text>
                      </>
                    )}
                  </Pressable>
                );
              })}
            </View> */}
            <View sx={{ flex: [null, 3], px: "$4" }}>
              <Text sx={{ fontSize: scale(20), fontWeight: 300 }}>
                {(data?.length || 0) === 0
                  ? `Không tìm thấy dữ liệu nào cho từ khoá "${keyword}"`
                  : `${data?.length} dữ liệu được tìm thấy cho từ khoá "${keyword}"`}
              </Text>
              <View sx={{ mt: "$3", bg: "$white" }}>
                {data?.map((item, index) => {
                  const { _index: type } = item;
                  if (type === INDEX_TYPE.CONDAN) {
                    return (
                      <SearchResultItem index={index} key={index} data={item} />
                    );
                  }
                  if (type === INDEX_TYPE.DOANHNGHIEP) {
                    return (
                      <BusinessResultItem
                        index={index}
                        key={index}
                        data={item}
                      />
                    );
                  }
                  return <View key={index} />;
                })}
              </View>
            </View>
          </View>
        )}
      </Container>
    </DefaultLayout>
  );
}
