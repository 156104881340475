import { View, Text, Pressable } from "dripsy";
import useScale from "../../hooks/useScale";
import { InfoItem } from  "./InfoItem";
import { EmptyData } from  "./EmptyData";

export const BusinessInfo = ({ data, onLinkPress }) => {
    const scale = useScale();
    return (
      <View sx={{ mt: "$2" }}>
        <Text
          sx={{
            py: "$2",
            px: "$2",
            mb: "$2",
            textAlign: "center",
            bg: "#367BDD",
            color: "$white",
            fontSize: [scale(14), scale(12)],
          }}
        >
          Doanh nghiệp
        </Text>
        {(data?.length || 0) === 0 ? (
          <EmptyData />
        ) : (
          data?.map((item, index) => {
            const { maSoThue, tenDoanhNghiep } = item || {};
            return (
              <Pressable
                key={index}
                sx={{
                  ...(index > 0 && {
                    borderTopWidth: 0.8,
                    borderTopColor: "grey",
                  }),
                }}
                onPress={() => onLinkPress(maSoThue)}
              >
                <InfoItem
                  label={"Tên doanh nghiệp"}
                  value={tenDoanhNghiep}
                  isLink={true}
                />
                <InfoItem label={"Mã số thuế"} value={maSoThue} />
              </Pressable>
            );
          })
        )}
      </View>
    );
  };