import { View, Text } from "dripsy";
import useScale from "../../hooks/useScale";
export const InfoItem = ({ label, value, isLink = false }) => {
    const scale = useScale();
    if (!value) return null;
    return (
      <View sx={{ flexDirection: "row", px: "$2", py: "$2" }}>
        <Text sx={{ flex: 1, fontWeight: 600, fontSize: [scale(14), scale(12)] }}>
          {label}
        </Text>
        <Text
          sx={{
            flex: 1,
            fontWeight: 400,
            fontSize: [scale(14), scale(12)],
            ...(isLink && {
              textDecorationLine: "underline",
              color: "rgb(54, 123, 221)",
            }),
          }}
        >
          {value}
        </Text>
      </View>
    );
  };