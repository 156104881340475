import { View } from "dripsy";

export default function Container({ flex, flexGrow, bg, children, onLayout }) {
  return (
    <View sx={{ flex, flexGrow, bg, alignItems: "center" }}>
      <View sx={{ width: ["100%", "85%", "73%"] }} onLayout={onLayout}>
        {children}
      </View>
    </View>
  );
}
