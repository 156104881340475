import React from "react";
//import Topics from "../components/Topics";
import Sections from "../components/Sections";
import DefaultLayout from "@layout/default";
import { Image } from "dripsy";

export default function Home() {
  return (
    <DefaultLayout>
      <> 
        {/* <Topics /> */}
        <Sections />
      </>
    </DefaultLayout>
  );
}
