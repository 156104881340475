import {UserManager, WebStorageStateStore} from 'oidc-client-ts';

console.log('process.env.VITE_OIDC_UR');

const mgr = new UserManager({
    authority: 'https://xacthuctaptrung.dtcsolution.vn/realms/quang-tri',
    client_id: 'ioc-frontend',
    redirect_uri: 'https://wiki.dtcsolution.vn/home',
    response_type: 'code',
    scope: 'openid profile email',
    post_logout_redirect_uri: 'https://wiki.dtcsolution.vn/home',
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({store: window.localStorage}),
});
export default mgr;
