import { useEffect, useState } from "react";
import { View, Text } from "dripsy";
import Container from "../components/Container";
import HttpClient from "../httpClient";
import DefaultLayout from "@layout/default";
import { useLinkTo } from "@react-navigation/native";
import Loading from "@components/Loading";
import { EmptyData } from  "./detail-info-components/EmptyData";
import { HealthInfo } from  "./detail-info-components/HealthInfo";
import { GeneralInfo } from  "./detail-info-components/GeneralInfo";
import { EducationInfo } from  "./detail-info-components/EducationInfo";
import { MilitaryServiceInfo } from  "./detail-info-components/MilitaryServiceInfo";
import { BusinessInfo } from  "./detail-info-components/BusinessInfo";
import { FamilyInfo } from  "./detail-info-components/FamilyInfo";
import { TrafficViolationInfo } from  "./detail-info-components/TrafficViolationInfo";
import { GplxInfo } from  "./detail-info-components/GplxInfo";
  
export default function Details({ route: { params: { id = "" } = {} } }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    suckhoe: sucKhoe,
    hoctap: hocTap,
    viphamgt: viPhamGT,
    nvqs,
    gplx,
  } = data?.node || {};

  const relationships = data?.relationships || [];

  const [slug, setSlug] = useState(id);

  const linkTo = useLinkTo();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await HttpClient.get({
          method: `/congdan/${slug}`,
        });
        setData(response);
        console.log("data", data);
      } catch (error) {
        console.log("fDetails.etchData.error", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [slug]);

  const familyRelationships = relationships.filter((item) =>
    Object.keys(item).includes("hoTen")
  );

  console.log("familyRelationships", familyRelationships);

  const businessRelationships = relationships.filter((item) =>
    Object.keys(item).includes("tenDoanhNghiep")
  );

  console.log("businessRelationships", businessRelationships);

  return (
    <DefaultLayout>
      <Container flexGrow={1}>
        {isLoading ? (
          <Loading />
        ) : data ? (
          <View sx={{ flexGrow: 1, flexDirection: "row", pt: "$3" }}>
            <View sx={{ flex: 2, bg: "$white", px: "$3", py: "$3" }}>
              <GeneralInfo data={data} showContent={true} showTitle={false} />
              {data && data.node && data.node.hocTap &&  <EducationInfo data={data?.node?.hocTap} showContent={true} />}
              {data && data.node && data.node.sucKhoe && <HealthInfo data={data?.node?.sucKhoe} showContent={true} />}
              {data && data.node && data.node.nvqs && <MilitaryServiceInfo data={data?.node?.nvqs} showContent={true} />}
              {data && data.node && data.node.viPhamGT && <TrafficViolationInfo data={data?.node?.viPhamGT} showContent={true} />}
              <GplxInfo data={gplx} />
            </View>
            <View sx={{ flex: 1, bg: "$white" }}>
              <GeneralInfo data={data} />
              <FamilyInfo
                data={familyRelationships}
                onLinkPress={(cccd) => setSlug(cccd)}
              />
              <BusinessInfo
                data={businessRelationships}
                onLinkPress={(maSoThue) =>
                  linkTo(`/business-details/${maSoThue}`)
                }
              />
            </View>
          </View>
        ) : (
          <EmptyData />
        )}
      </Container>
    </DefaultLayout>
  );
}
