import { View, Text } from "dripsy";
import useScale from "../../hooks/useScale";
import { ContentContainer } from  "./ContentContainer";
import { InfoItem } from  "./InfoItem";
import isEmpty from "lodash/isEmpty";

export const MilitaryServiceInfo = ({ data, showContent }) => {
    const scale = useScale();
    const hasNoData = isEmpty(data);
    const { thoiGianBatDau, thoiGianKetThuc, donVi, diaDiem } = data || {};
    if (showContent) {
      return (
        !hasNoData && (
          <ContentContainer title={"Nghĩa vụ quân sự"}>
            <View sx={{ py: "$2" }}>
              {Array.isArray(data) ? (
                <>
                  {data?.map((item, index) => (
                    <Text
                      sx={{ mt: index > 0 ? "$2" : null }}
                      key={`${index}`}
                    >{`Thực hiện nghĩa vụ quân sự từ ${item.thoiGianBatDau} đến ${item.thoiGianKetThuc} tại đơn vị ${item.donVi}, ${item.diaDiem}`}</Text>
                  ))}
                </>
              ) : (
                <Text>{`Thực hiện nghĩa vụ quân sự từ ${thoiGianBatDau} đến ${thoiGianKetThuc} tại đơn vị ${donVi}, ${diaDiem}`}</Text>
              )}
            </View>
          </ContentContainer>
        )
      );
    }
    return (
      <View sx={{ mt: "$2" }}>
        <Text
          sx={{
            py: "$2",
            px: "$2",
            mb: "$2",
            textAlign: "center",
            bg: "#367BDD",
            color: "$white",
            fontSize: [scale(14), scale(12)],
          }}
        >
          Nghĩa vụ quân sự
        </Text>
        {hasNoData ? (
          <EmptyData />
        ) : Array.isArray(data) ? (
          <>
            {data?.map((item, index) => (
              <View key={`${index}`}>
                <InfoItem
                  label={"Thời gian bắt đầu"}
                  value={item.thoiGianBatDau}
                />
                <InfoItem
                  label={"Thời gian kết thúc"}
                  value={item.thoiGianKetThuc}
                />
                <InfoItem label={"Đơn vị"} value={item.donVi} />
                <InfoItem label={"Địa điểm"} value={item.diaDiem} />
              </View>
            ))}
          </>
        ) : (
          <>
            <InfoItem label={"Thời gian bắt đầu"} value={thoiGianBatDau} />
            <InfoItem label={"Thời gian kết thúc"} value={thoiGianKetThuc} />
            <InfoItem label={"Đơn vị"} value={donVi} />
            <InfoItem label={"Địa điểm"} value={diaDiem} />
          </>
        )}
      </View>
    );
  };