require("dayjs/locale/vi");
import dayjs from "dayjs";
var isToday = require("dayjs/plugin/isToday");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var relativeTime = require("dayjs/plugin/relativeTime");
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

dayjs.extend(relativeTime);
dayjs.locale("vi");
dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(timezone);

export const formatDate = (dateStr, format) => {
  if (!dateStr) return "";
  try {
    return dayjs(dateStr).format(format);
  } catch (error) {
    return "";
  }
};

export const stringToDate = (dateStr, format) => {
  if (!dateStr) return "";
  try {
    dayjs(dateStr, format);
  } catch (error) {
    return "";
  }
};

export const formatDateStr = (dateStr, currentFormat, newFormat) => {
  return formatDate(stringToDate(dateStr, currentFormat), newFormat);
};
