import { View, Text } from "dripsy";
import useScale from "../../hooks/useScale";
import { ContentContainer } from  "./ContentContainer";
import { InfoItem } from  "./InfoItem";
import isEmpty from "lodash/isEmpty";

export const TrafficViolationInfo = ({ data, showContent = false }) => {
    const scale = useScale();
    const hasNoData = isEmpty(data);
  
    if (showContent) {
      return (
        !hasNoData && (
          <ContentContainer title={"Vi phạm giao thông"}>
            <View sx={{ py: "$2" }}>
              {data?.map((item, index) => {
                const {
                  bienKS,
                  mauBien,
                  loaiPhuongTien,
                  thoiGianViPham,
                  diaDiemViPham,
                  trangThai,
                  donViPhatHien,
                  noiGiaiQuyet,
                } = item;
                return (
                  <Text sx={{ mt: index > 0 ? "$2" : null }} key={index}>
                    {`Phương tiện ${loaiPhuongTien}, biển kiếm soát ${bienKS}, vi phạm giao thông tại ${diaDiemViPham} lúc ${thoiGianViPham}`}
                  </Text>
                );
              })}
            </View>
          </ContentContainer>
        )
      );
    }
  
    return (
      <View sx={{ mt: "$2" }}>
        <Text
          sx={{
            py: "$2",
            px: "$2",
            mb: "$2",
            textAlign: "center",
            bg: "#367BDD",
            color: "$white",
            fontSize: [scale(14), scale(12)],
          }}
        >
          Vi phạm giao thông
        </Text>
  
        {(data?.length || 0) === 0 ? (
          <EmptyData />
        ) : (
          data?.map((item, index) => {
            const {
              bienKS,
              mauBien,
              loaiPhuongTien,
              thoiGianViPham,
              diaDiemViPham,
              trangThai,
              donViPhatHien,
              noiGiaiQuyet,
            } = item;
            return (
              <View
                key={index}
                sx={{ borderBottomWidth: 0.8, borderBottomColor: "grey" }}
              >
                <InfoItem label={"Biển kiếm soát"} value={bienKS} />
                <InfoItem label={"Màu biển"} value={mauBien} />
                <InfoItem label={"Loại phương tiện"} value={loaiPhuongTien} />
                <InfoItem label={"Thời gian vi phạm"} value={thoiGianViPham} />
                <InfoItem label={"Địa điểm vi phạm"} value={diaDiemViPham} />
                <InfoItem label={"Trạng thái"} value={trangThai} />
                <InfoItem label={"Đơn vị phát hiện"} value={donViPhatHien} />
                <InfoItem label={"Nơi giải quyết"} value={noiGiaiQuyet} />
              </View>
            );
          })
        )}
      </View>
    );
  };